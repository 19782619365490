<template>
    <div class="subpage-wrapper d-block" v-if="token && isPageModalOpen">
        <div class="subpage-container">
            <button class="close-subpage" @click="closePageModal()">
                <i class="fa-solid fa-xmark"></i>
            </button>
            <div class="subpage-inner">
                <div class="modal-contents">
                    <div class="side-menu">
                        <div class="sidemenu-header">선택 목록</div>
                        <div class="menu">
                            <a
                                href="javascript:void(0)"
                                class="menu-item"
                                data_tab="tab1"
                                @click="changeTab('tab1')"
                                ><i class="fa-solid fa-piggy-bank"></i
                                >{{ langweb("fe.cashin") }}</a
                            >
                            <a
                                href="javascript:void(0)"
                                class="menu-item"
                                data_tab="tab3"
                                @click="changeTab('tab3')"
                                ><i class="fa-solid fa-vault"></i
                                >{{ langweb("fe.cashout") }}</a
                            >
                            <a
                                href="javascript:void(0)"
                                class="menu-item"
                                data_tab="tab4"
                                @click="changeTab('tab4')"
                                ><i class="fa-solid fa-list"></i
                                >{{ langweb("fe.history") }}</a
                            >
                            <a
                                href="javascript:void(0)"
                                class="menu-item"
                                data_tab="tab8"
                                @click="changeTab('tab8')"
                                ><i
                                    class="fa-solid fa-money-bill-transfer"
                                ></i
                                >{{ langweb("fe.pointtoamount") }}</a
                            >
                            <a
                                href="javascript:void(0)"
                                class="menu-item"
                                data_tab="tab5"
                                @click="changeTab('tab5')"
                                ><i class="fa-solid fa-bell"></i
                                >{{ langweb("fe.notification") }}</a
                            >
                            <a
                                href="javascript:void(0)"
                                class="menu-item"
                                data_tab="tab6"
                                @click="changeTab('tab6')"
                                ><i class="fa-solid fa-comment-dots"></i
                                >{{ langweb("fe.chat") }}</a
                            >
                            <a
                                href="javascript:void(0)"
                                class="menu-item"
                                data_tab="tab11"
                                @click="changeTab('tab11')"
                                ><i class="fa-solid fa-comments"></i
                                >{{ langweb("fe.sends") }}</a
                            >
                            <a
                                href="javascript:void(0)"
                                class="menu-item"
                                data_tab="tab7"
                                @click="changeTab('tab7')"
                                ><i class="fa-solid fa-circle-info"></i
                                >{{ langweb("fe.info") }}</a
                            >
                            <a
                                href="javascript:void(0)"
                                class="menu-item"
                                data_tab="tab9"
                                @click="changeTab('tab9')"
                                ><i
                                    class="fa-solid fa-clock-rotate-left"
                                ></i
                                >{{ langweb("fe.betting") }}</a
                            >
                            <a
                                href="javascript:void(0)"
                                class="menu-item"
                                data_tab="tab10"
                                @click="changeTab('tab10')"
                                ><i class="fa-solid fa-gift"></i
                                >{{ langweb("fe.gift") }}</a
                            >
                            <a
                                href="javascript:void(0)"
                                class="menu-item"
                                data_tab="tab12"
                                @click="changeTab('tab12')"
                                ><i class="fa-solid fa-gift"></i
                                >FAQ</a
                            >
                        </div>
                    </div>
                    <div class="subpage-content">
                        <div class="subpagecontent-header">
                            {{ tabTitle }}
                        </div>
                        <div class="content">
                            <div
                                id="deposit"
                                class="active tab1"
                                v-if="tabSelected === 'tab1'"
                            >
                                <Cashin_city />
                            </div>
                            <div
                                id="withdraw"
                                class="active tab3"
                                v-else-if="tabSelected === 'tab3'"
                            >
                                <Cashout_city />
                            </div>
                            <div
                                id="announcement"
                                class="table-responsive active tab5"
                                v-else-if="tabSelected === 'tab5'"
                            >
                                <Notification_city/>
                            </div>
                            <div
                                id="conversion"
                                class="active tab8"
                                v-else-if="tabSelected === 'tab8'"
                            >
                                <PointToAmount_city />
                            </div>
                            <div
                                id="inquiry"
                                class="table-responsive active tab6"
                                v-else-if="tabSelected === 'tab6'"
                            >
                                <Chat_city />
                                <Contact_city />
                            </div>
                            <div
                                id="note"
                                class="active tab11"
                                v-else-if="tabSelected === 'tab11'"
                            >
                                <Send_city />
                            </div>
                            <div
                                id="transaction-details"
                                class="table-responsive active tab4"
                                v-else-if="tabSelected === 'tab4'"
                            >
                                <ListCashout_city />
                            </div>
                            <div
                                id="mypage"
                                class="active tab7"
                                v-else-if="tabSelected === 'tab7'"
                            >
                                <ChangeInfo_city />
                            </div>
                            <div
                                id="betting-details"
                                class="table-responsive active tab9"
                                v-else-if="tabSelected === 'tab9'"
                            >
                                <Betting_city />
                            </div>
                            <div
                                id="faq"
                                class="table-responsive active tab12"
                                v-else-if="tabSelected === 'tab12'"
                            >
                                <FAQ/>
                            </div>
                            <div
                                id="coupon"
                                class="table-responsive active tab10"
                                v-else
                            >
                                <Gift_city />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wraper-root">
        <!-- <Carousel class="banner-section w-ba" :autoplay="2000" :wrap-around="true">
            <Slide v-for="slide in banners" :key="slide">
                <img class="banner-bg" :src="slide.imgURL" />
            </Slide>
        </Carousel> -->
        <div class="banner-section">

        </div>
        <main class="page-content w-ba" id="games-section">
            <section class="toggle-section">
                <div class="container dflex-ac-jc">
                    <button :class="gameTab === 'casino' ? 'toggle-btn casino-toggle active':'toggle-btn casino-toggle'" @click="$store.commit('changeTab', 'casino')">
                        <div class="btn-panel dflex-ac-js w-ba ml-auto">
                            <div class="icon-panel w-ba dflex-ac-jc"> <img src="https://cdn.thecontentsnetworksystem.com/sample1/images/icon/casino-icon.png"> </div>
                            <span class="category">홀덤/맞고/바둑이</span> <i class="fa-solid fa-caret-down indicator ml-md-3 ml-1"></i>
                        </div>
                    </button>
                    <button :class="gameTab === 'slot' ? 'toggle-btn slot-toggle active':'toggle-btn slot-toggle'"  @click="$store.commit('changeTab', 'slot')">
                        <div class="btn-panel dflex-ac-je w-ba ms-auto">
                            <div class="ml-auto">
                                <i class="fa-solid fa-caret-down indicator ms-md-3 mr-2"></i>
                                <span class="category right">슬롯게임</span>
                            </div>
                            <div class="icon-panel w-ba dflex-ac-jc ms-auto"> <img src="https://cdn.thecontentsnetworksystem.com/sample1/images/icon/slot-icon.png"> </div>
                        </div>
                    </button>
                </div>
            </section>
            <section class="toggle-section">\
                <div class="tab-content-toogle game-list">
                    <section class="game-provider">
                        <div class="container">
                            <div class="tab1" v-if="gameTab === 'casino'">
                                <div class="casino-section sc-section">
                                    <div class="w-100 game-card-parent casino">
                                        <a href="javascript:void(0)" v-for="(item, index) in listGameCasino" :key="index" class="sc-btn w-ba" style="animation-delay: 1.5s;">
                                            <div class="g-panel w-ba">
                                                <div class="g-cont"> 
                                                    <!--<img class="g-img" src="https://cdn.thecontentsnetworksystem.com/sample1/images/provider/casino/sagaming.jpg?v=1"> -->
                                                    <img class="g-img" :src="item.image">
                                                    <button type="button" class="play-btn btn-yellow" v-if="token === null" @click="showModal = true">게임입장</button>
                                                    <button type="button" class="play-btn btn-yellow" v-else-if="item.maintenance == 1" @click="getMaintenance()">게임입장</button>
                                                    <button type="button" class="play-btn btn-yellow" v-else @click=" getGameProcess(item.vender,item.code,1)">게임입장</button>
                                                </div>
                                                <div class="g-footer w-ba">
                                                    <div class="g-logo dflex-ac-jc w-ba"> 
                                                        <img class="icon-img" :src="imgs[`img${item.code}`]">
                                                    </div>
                                                </div>
                                                <div class="g-info">
                                                    <span class="g-name">{{item.title}}</span>
                                                    <span class="en-text">{{item.title}}</span>
                                                </div>
                                                <div class="glass"></div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="tab2" v-if="gameTab === 'slot'">
                                <div class="slot-section sc-section active">
                                    <div class="game-card-parent slot w-100">
                                        <a href="javascript:void(0)" v-for="(item, index) in listGameSlot" :key="index" class="sc-btn w-ba" style="animation-delay: 1.5s;">
                                            <div class="g-panel w-ba">
                                                <div class="g-cont"> 
                                                    <!-- <img class="g-img" src="https://cdn.thecontentsnetworksystem.com/sample1/images/provider/slot/1x2gaming.jpg?v=1"> -->
                                                    <img class="g-img" :src="item.image">
                                                    <button type="button" class="play-btn btn-yellow" v-if="token === null" @click="showModal = true">게임입장</button>
                                                    <button type="button" class="play-btn btn-yellow" v-else-if="item.maintenance == 1" @click="getMaintenance()">게임입장</button>
                                                    <button type="button" class="play-btn btn-yellow" v-else @click=" getGameProcess(item.vender,item.code,2)">게임입장</button>
                                                </div>
                                                <div class="g-footer w-ba">
                                                    <div class="g-logo dflex-ac-jc w-ba"> 
                                                        <img class="icon-img" :src="imgs[`img${item.code}`]">
                                                    </div>
                                                </div>
                                                <div class="g-info">
                                                    <span class="g-name">{{item.title}}</span>
                                                    <span class="en-text">{{item.title}}</span>
                                                </div>
                                                <div class="glass"></div>
                                            </div>
                                        </a>


                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section class="contact-section">
                <div class="container">
                    <div>
                        <!-- <img src="../assets/img/icons/telegram.svg" alt=""> -->
                    </div>
                </div>
            </section>
            <input type="hidden" name="datacode" class="datacode" value="0" />
            <div
                id="loading_show"
                class="loading_show"
                style="
                    position: fixed;
                    left: 0;
                    bottom: 0;
                    top: 0%;
                    right: 0%;
                    text-align: center;
                    background: rgba(0, 0, 0, 0.5);
                    z-index: 999999999;
                "
            >
                <div class="windows8">
                    <img src="../assets/img/icons/loader.svg" alt="" />
                </div>
            </div>
            <!-- item game click slot -->
            <div class="root-cash popupItemGame" v-if="token !== null">
                <div class="wraper-cash">
                    <div class="cash-tab">
                        <div class="tabcash">
                            <div class="titmenu_popup">
                                {{ langweb("fe.text1") }}
                                <img src="../assets/images/right.svg" alt="" />
                            </div>
                            <ul>
                                <li>
                                    <form
                                        action=""
                                        method=""
                                        name="frm_search"
                                        id="_frm_search"
                                    >
                                        <div class="input_search">
                                            <input
                                                type="text"
                                                v-model="search"
                                                class="key_search"
                                                placeholder="Search"
                                                @keyup="searchGame"
                                            />
                                        </div>
                                    </form>
                                </li>
                                <li
                                    v-bind:class="{
                                        active: tabItemGame == 'taba'
                                    }"
                                    v-on:click="changeTabItemGame('taba')"
                                >
                                    <a>
                                        <font-awesome-icon
                                            icon="fa-solid fa-clover"
                                        />&nbsp; {{ langweb("fe.text3") }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="root-content-cash-tab">
                            <div class="content-tab-cashin">
                                <div class="taba" v-if="tabItemGame === 'taba'">
                                    <div class="text_category">
                                        <div>
                                            <font-awesome-icon
                                                icon="fa-solid fa-clover"
                                            />&nbsp;
                                            <span class="namegaming"></span>
                                        </div>
                                    </div>
                                    <div
                                        class="root_main_cash dflex-ac-jc w-100 wrapper_tab_game"
                                    >
                                        <a
                                            href="javascript:void(0);"
                                            @click="
                                                getGameProcess(
                                                    item.vender,
                                                    item.code,
                                                    2
                                                )
                                            "
                                            class="itemgamePP"
                                            v-for="(item, index) in datalt"
                                            v-bind:key="index"
                                        >
                                            <div class="wp-itemgpp">
                                                <img
                                                    class="g-img"
                                                    :src="item.image"
                                                />
                                                <div class="nameitem_game">
                                                    <span>{{
                                                        item.title
                                                    }}</span>
                                                </div>
                                            </div>
                                        </a>
                                        <paginate
                                            class="container_paginater"
                                            @update:modelValue="clickCallbacklt"
                                            :totalCount="totalCountlt"
                                            :limit="perPagelt"
                                            v-model="pagelt"
                                        ></paginate>
                                    </div>
                                </div>
                                <div class="tabb" v-if="tabItemGame === 'tabb'">
                                    <div class="text_category">
                                        <div>
                                            <font-awesome-icon
                                                icon="fa-solid fa-bomb"
                                            />&nbsp; {{ langweb("fe.text4") }}
                                        </div>
                                    </div>
                                    <div
                                        class="root_main_cash dflex-ac-jc w-100 wrapper_tab_game"
                                    >
                                        {{ listGamingsearch }}
                                        <div
                                            class="card"
                                            v-for="(
                                                post, index
                                            ) in listGamingsearch"
                                            :key="index"
                                        >
                                            <a>
                                                {{ post.title }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img
                            src="../assets/images/closes.svg"
                            alt=""
                            @click="closePopupItemGame"
                            class="closetab"
                        />
                    </div>
                </div>
            </div>
            <!-- end item game click slot -->
        </main>
        <div id="modal-example" class="modal-vue">
            <div
                class="overlay"
                v-if="showModal"
                @click="showModal = false"
            ></div>
            <div class="modal" v-if="showModal">
                <div class="wp-modal">
                    <div class="header-modal">
                        <div class="title-header">
                            {{ langweb("fe.notification") }}
                        </div>
                        <button class="close-modal" @click="showModal = false">
                            x
                        </button>
                    </div>
                    <div class="content-modal">
                        <div class="text-content">
                            {{ langweb("fe.texts") }}
                        </div>
                    </div>
                    <div class="footer-modal">
                        <button class="config-modal" @click="showModal = false">
                            {{ langweb("fe.ok") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal maintenance game -->
        <div id="modal-example" class="modal-vue">
            <div
                class="overlay"
                v-if="showModal_bt"
                @click="showModal_bt = false"
            ></div>
            <div class="modal" v-if="showModal_bt">
                <div class="wp-modal">
                    <div class="header-modal">
                        <div class="title-header">
                            {{ langweb("fe.notification") }}
                        </div>
                        <button
                            class="close-modal"
                            @click="showModal_bt = false"
                        >
                            x
                        </button>
                    </div>
                    <div class="content-modal">
                        <div class="text-content">
                            {{ langweb("fe.maintenance") }}
                        </div>
                    </div>
                    <div class="footer-modal">
                        <button
                            class="config-modal"
                            @click="showModal_bt = false"
                        >
                            {{ langweb("fe.ok") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End modal maintenance game -->
    </div>

    <div
        v-if="$isMobile()"
        id="popupID1736"
        class="popup_window"
        style="width: 350px; height: 350px; top: 0px; left: 0px; display: none"
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop1.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 350px;
                                height: 350px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1736')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1736')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div
        v-else
        id="popupID1736"
        class="popup_window"
        style="
            width: 450px;
            height: 600px;
            top: 300px;
            left: 50px;
            display: none;
        "
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop1.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 450px;
                                height: 600px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1736')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1736')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div
        v-if="$isMobile()"
        id="popupID1737"
        class="popup_window"
        style="width: 350px; height: 350px; top: 0px; left: 0px; display: none"
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop2.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 350px;
                                height: 350px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1737')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1737')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div
        v-else
        id="popupID1737"
        class="popup_window"
        style="
            width: 450px;
            height: 600px;
            top: 300px;
            left: 500px;
            display: none;
        "
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop2.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 450px;
                                height: 600px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1737')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1737')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div
        v-if="$isMobile()"
        id="popupID1738"
        class="popup_window"
        style="width: 350px; height: 350px; top: 0px; left: 0px; display: none"
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop3.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 350px;
                                height: 350px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1738')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1738')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div
        v-else
        id="popupID1738"
        class="popup_window"
        style="
            width: 450px;
            height: 600px;
            top: 300px;
            left: 950px;
            display: none;
        "
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop3.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 450px;
                                height: 600px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1738')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1738')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div
        v-if="$isMobile()"
        id="popupID1739"
        class="popup_window"
        style="width: 350px; height: 350px; top: 0px; left: 0px; display: none"
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop4.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 350px;
                                height: 350px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1739')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1739')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div
        v-else
        id="popupID1739"
        class="popup_window"
        style="
            width: 450px;
            height: 600px;
            top: 300px;
            left: 1400px;
            display: none;
        "
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop4.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 450px;
                                height: 600px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1739')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1739')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import $ from "jquery";
import { mapState } from 'vuex';
// import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import Cashin_city from "@/components/Cashin";
// import ListCashin_city from "@/components/ListCashIn";
import Cashout_city from "@/components/Cashout";
import ListCashout_city from "@/components/ListCashOut";
import Notification_city from "@/components/Notification";
import Chat_city from "@/components/ChatList";
import Contact_city from "@/components/SendChat";
import ChangeInfo_city from "@/components/ChangeInfo";
import PointToAmount_city from "@/components/PointToAmount";
import Betting_city from "@/components/Betting";
import Gift_city from "@/components/Gift";
import Send_city from "@/components/Send";
import FAQ from "@/components/FAQ";
import img28001 from "@/assets/img/providers/casino/logo/28001.png";
import img30001 from "@/assets/img/providers/casino/logo/30001.png";
import img86002 from "@/assets/img/providers/casino/logo/86002.png";
import img407000 from "@/assets/img/providers/casino/logo/407000.png";
import imgwec from "@/assets/img/providers/casino/logo/wec.png";

import img17 from "@/assets/img/providers/slot/provider-logo/17.png";
import img20 from "@/assets/img/providers/slot/provider-logo/20.png";
import img31 from "@/assets/img/providers/slot/provider-logo/31.png";
import img33 from "@/assets/img/providers/slot/provider-logo/33.png";
import img39 from "@/assets/img/providers/slot/provider-logo/39.png";
import img41 from "@/assets/img/providers/slot/provider-logo/41.png";
import img43 from "@/assets/img/providers/slot/provider-logo/43.png";
import img45 from "@/assets/img/providers/slot/provider-logo/45.png";
import img51 from "@/assets/img/providers/slot/provider-logo/51.png";
import img53 from "@/assets/img/providers/slot/provider-logo/53.png";
import img59 from "@/assets/img/providers/slot/provider-logo/59.png";
import img61 from "@/assets/img/providers/slot/provider-logo/61.png";
import img63 from "@/assets/img/providers/slot/provider-logo/63.png";
import img66 from "@/assets/img/providers/slot/provider-logo/66.png";
import img300 from "@/assets/img/providers/slot/provider-logo/300.png";

$(document).ready(function () {
    $(document).on("click", ".notifyhome_click", function () {
        $(this).parent().find(".substring_content_tbs").show();
        $(".toggle-section").css("z-index", "-1");
        $(".footer-section").css("z-index", "-1");
        // $("body").css("overflow", "hidden");
    });
    $(document).on("click", ".close_notis", function () {
        $(this).parents(".substring_content_tbs").hide();
        $(".toggle-section").css("z-index", "0");
        $(".footer-section").css("z-index", "0");
        $("body").css("overflow", "initial");
    });
    $(document).on("click", ".notifyhome_clicks", function () {
        $(this).parent().find(".substring_content_tbss").show();
        $(".toggle-section").css("z-index", "-1");
        $(".footer-section").css("z-index", "-1");
        // $("body").css("overflow", "hidden");
    });
    $(document).on("click", ".close_notiss", function () {
        $(this).parents(".substring_content_tbss").hide();
        $(".toggle-section").css("z-index", "0");
        $(".footer-section").css("z-index", "0");
        // $("body").css("overflow", "initial");
    });
    $(document).on("click", ".more-btn", function () {
        $('.subpage-content .subpagecontent-header').text(`${$(this).siblings().text()}`)
    })
});

export default {
    name: "Home_city",
    setup() {
        const store = useStore();
        const lang = computed(() => store.state.lang);
        const lang_en = computed(() => store.state.language);
        store.dispatch("changLang");
        return {
            langweb(variable) {
                lang.value.filter(function (e) {
                    if (e.variable == variable) {
                        if (lang_en.value == "en") {
                            variable = e.en;
                        } else {
                            variable = e.kr;
                        }
                    }
                });
                return variable;
            }
        };
    },
    computed: {
        ...mapState(['gameTab']),
    },
    data() {
        return {
            imgs:{
                img28001:img28001,
                img30001:img30001,
                img86002:img86002,
                img407000:img407000,
                imgwec:imgwec,
                img17:img17,
                img20:img20,
                img31:img31,
                img33:img33,
                img39:img39,
                img41:img41,
                img43:img43,
                img45:img45,
                img51:img51,
                img53:img53,
                img59:img59,
                img61:img61,
                img63:img63,
                img66:img66,
                img300:img300
            },
            tabSelected: "tab1",
            tabSelecteds: "tab3",
            tabTitle: "",
            showModal: false,
            showModal_bt: false,
            hoveredIndex: null,
            listchat: [],
            token: window.localStorage.getItem("token"),
            listCashouthome: [],
            listcashinhome: [],
            listnotifihome: [],
            listGameCasino: [],
            listGameSlot: [],
            modalcoin: false,
            id_user: "1",
            title: "",
            question_content: "",
            listtitlehome: [],
            tabItemGame: "taba",
            itemgamepp: [],
            pagelt: 1,
            perPagelt: 35,
            totalCountlt: 0,
            datalt: [],
            listSearch: [],
            search: "",
            listGaming: [],
            listGamingsearch: [],
            timeout_icon: null,
            listFaq: [],
            banners: [
                {
                    imgURL: require("@/assets/img/banner/banner-1.png")
                },
                {
                    imgURL: require("@/assets/img/banner/banner-2.png")
                },
                {
                    imgURL: require("@/assets/img/banner/banner-3.png")
                }
            ],
            selectedPopOver:null,
            odometer: null,
            isPageModalOpen:false,
        };
    },
    components: {
        // Carousel,
        // Slide,
        Cashin_city,
        Cashout_city,
        ListCashout_city,
        Notification_city,
        Chat_city,
        Contact_city,
        ChangeInfo_city,
        PointToAmount_city,
        Betting_city,
        Gift_city,
        Send_city,
        FAQ
    },
    created() {
        if (this.token !== null) {
            this.getListnotifiHome();
            this.getListFaq();
        } else {
            this.getListFaqLogin();
            this.getListnotifiHomelist();
        }

        this.getListcashoutHome();
        this.getListcashinHome();
        this.getListGameCasino();
        this.getListGameSlot();
    },
    methods: {
        closePageModal(){
            this.isPageModalOpen = false
            $('body').css('overflow','auto')
        },
        showMain(event) {
            this.isPageModalOpen = true
            if(this.token){
                var id = Number(event.target.getAttribute("data_id"));
                if (id === 1) {
                    this.tabSelected = "tab1";
                } else if (id === 2) {
                    this.tabSelected = "tab3";
                } else if (id === 3) {
                    this.notifi = "0";
                    this.tabSelected = "tab5";
                } else if (id === 4) {
                    this.chat = "0";
                    this.tabSelected = "tab6";
                } else if (id === 5) {
                    this.tabSelected = "tab7";
                } else if (id === 6) {
                    this.tabSelected = "tab8";
                }else if (id === 12) {
                    this.tabSelected = "tab12";
                } else {
                    this.tabSelected = "tab11";
                }
                $("body").css("overflow", "hidden");
                this.toggleMobileMenu = false;
            }else{
                alert('Invalid Token')
            }
        },
        showPopup(name) {
            if (this.$cookies.get(name) == "popup") {
                document.getElementById(name).style.display = "none";
            } else {
                document.getElementById(name).style.display = "block";
            }
        },
        hidePopup(name) {
            this.$cookies.set(name, "popup", "1d");
            document.getElementById(name).style.display = "none";
        },
        popupClose(e) {
            document.getElementById(e).style.display = "none";
        },
        dragElement(t) {
            var a = 0,
                n = 0,
                i = 0,
                o = 0;
            function e(e) {
                (e = e || window.event),
                    (i = e.clientX),
                    (o = e.clientY),
                    (document.onmouseup = l),
                    (document.onmousemove = s);
            }
            function s(e) {
                (e = e || window.event),
                    (a = i - e.clientX),
                    (n = o - e.clientY),
                    (i = e.clientX),
                    (o = e.clientY),
                    (t.style.top = t.offsetTop - n + "px"),
                    (t.style.left = t.offsetLeft - a + "px");
            }
            function l() {
                (document.onmouseup = null), (document.onmousemove = null);
            }
            document.getElementById(t.id + "header")
                ? (document.getElementById(t.id + "header").onmousedown = e)
                : (t.onmousedown = e);
        },
        changeTab(tab) {
            this.tabTitle = event.target.textContent;
            this.tabSelected = tab;
        },
        changeTabs(tab) {
            this.tabSelecteds = tab;
        },
        changeTabItemGame(tab) {
            this.tabItemGame = tab;
        },
        getListcashoutHome() {
            // var token_user = window.localStorage.getItem('token')
            // this.axios.get(this.api_listCo, {
            //     headers: {
            //         Authorization: 'Bearer ' + token_user
            //     }
            // }).then((response) => {
            //     this.listCashouthome = response.data.data
            // })
            let today = new Date();
            let date =
                today.getDate() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getFullYear();
            this.listCashouthome = [
                { fullname: "aa***", amount: "1000000", created_at: date },
                { fullname: "db***", amount: "2000000", created_at: date },
                { fullname: "hg***", amount: "3500000", created_at: date },
                { fullname: "tg***", amount: "800000", created_at: date },
                { fullname: "wj***", amount: "1000000", created_at: date },
                { fullname: "qb***", amount: "3000000", created_at: date },
                { fullname: "mk***", amount: "500000", created_at: date },
                { fullname: "sx***", amount: "1000000", created_at: date },
                { fullname: "zj***", amount: "4000000", created_at: date },
                { fullname: "zj***", amount: "2000000", created_at: date }
            ];
        },
        getListcashinHome() {
            // var token_user = window.localStorage.getItem('token')
            // this.axios.get(this.api_listCi, {
            //     headers: {
            //         Authorization: 'Bearer ' + token_user
            //     }
            // }).then((response) => {
            //     this.listcashinhome = response.data.data
            // })
            let today = new Date();
            let date =
                today.getDate() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getFullYear();
            this.listcashinhome = [
                { fullname: "nm***", amount: "500000", created_at: date },
                { fullname: "vc***", amount: "300000", created_at: date },
                { fullname: "bf***", amount: "1000000", created_at: date },
                { fullname: "pl***", amount: "800000", created_at: date },
                { fullname: "qy***", amount: "500000", created_at: date },
                { fullname: "io***", amount: "3000000", created_at: date },
                { fullname: "ka***", amount: "5000000", created_at: date },
                { fullname: "ok***", amount: "1000000", created_at: date },
                { fullname: "xc***", amount: "800000", created_at: date },
                { fullname: "dh***", amount: "1500000", created_at: date }
            ];
        },
        getListnotifiHome() {
            var token_user = window.localStorage.getItem("token");
            this.axios
                .get(this.api_listnotifi, {
                    headers: {
                        Authorization: "Bearer " + token_user
                    }
                })
                .then((response) => {
                    this.listnotifihome = response.data.data;
                });
        },
        getListnotifiHomelist() {
            this.axios.get(this.api_listnotifilist, {}).then((response) => {
                this.listnotifihome = response.data.data;
            });
        },
        getListFaq() {
            var token_user = window.localStorage.getItem("token");
            this.axios
                .get(this.api_listFaq, {
                    headers: {
                        Authorization: "Bearer " + token_user
                    }
                })
                .then((response) => {
                    this.listFaq = response.data.data.list_data;
                });
        },
        getListFaqLogin() {
            this.axios.get(this.api_listFaq_login, {}).then((response) => {
                this.listFaq = response.data.data.list_data;
            });
        },
        getListGameCasino() {
            this.axios.get(this.api_gamecasino).then((response) => {
                this.listGameCasino = response.data.data.filter(
                    (d) => d.type === "casino"
                );
                // console.log(this.listGameCasino);

                // //this.showPopup('popupID1736');
                // //this.showPopup('popupID1737');
                // // this.showPopup('popupID1738');
                // //this.showPopup('popupID1739');
            });
        },
        getListGameSlot () {
            this.axios.get(this.api_gamecasino).then((response) => {
                // console.log(response.data.data);
                this.listGameSlot = response.data.data.filter(d => d.type === 'slot')
            })
        },
        // listTitle () {
        //     var token_user = window.localStorage.getItem('token')
        //     this.axios.get(this.api_listtitle, {
        //         headers: {
        //             Authorization: 'Bearer ' + token_user
        //         }
        //     }).then((response) => {
        //         this.listtitlehome = response.data.data.filter(d => d.type == 1)
        //         this.title = this.listtitlehome[0]?.id
        //     })
        // },
        getGameProcess(api, code, type) {
            $(".loading_show").show().fadeIn(10);
            var token_user = window.localStorage.getItem("token");
            this.axios
                .post(
                    this.api_liveprocess,
                    {
                        gamecode: code,
                        gametype: type,
                        gameapi: api
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + token_user
                        }
                    }
                )
                .then((response) => {
                    if (response.data.data.result == 1) {
                        var size = {
                            width:
                                window.innerWidth || document.body.clientWidth,
                            height:
                                window.innerHeight || document.body.clientHeight
                        };
                        //창 크기 지정
                        var width = size.width;
                        var height = size.height;
                        //pc화면기준 가운데 정렬
                        var left = window.screen.width / 2 - width / 2;
                        var top = window.screen.height / 4;
                        //윈도우 속성 지정
                        var windowStatus =
                            "width=" +
                            width +
                            ", height=" +
                            height +
                            ", left=" +
                            left +
                            ", top=" +
                            top +
                            ", scrollbars=yes, status=yes, resizable=yes, titlebar=yes";
                        //연결하고싶은url
                        const url = response.data.data.url;
                        //등록된 url 및 window 속성 기준으로 팝업창을 연다.
                        window.open(url, "gamepopup", windowStatus);
                        $("#loading_show").css("display", "none");
                    } else {
                        this.$swal({
                            position: "top-end",
                            icon: "error",
                            title: response.data.data.url,
                            showConfirmButton: false,
                            timer: 3000
                        });
                        $("#loading_show").css("display", "none");
                    }
                });
        },
        showItemGame(event) {
            $(".loading_show").show().fadeIn(10);
            var dataid = event.target.getAttribute("data_id");
            var dataname = event.target.getAttribute("data_name");
            $(".datacode").val(dataid);
            $(".namegaming").text(dataname);
            let codeid = $(".datacode").val();
            $(".popupItemGame").show();
            $(".wraper-root").addClass("z_index_in");
            $("body").css("overflow", "hidden");

            var token_user = window.localStorage.getItem("token");
            this.axios
                .get(this.api_listgameSlot + codeid, {
                    headers: {
                        Authorization: "Bearer " + token_user
                    }
                })
                .then((response) => {
                    this.listGaming = response.data.data;
                    this.itemgamepp = response.data.data;
                    this.totalCountlt = this.itemgamepp.length;
                    this.paginatedDatalt();
                    $("#loading_show").css("display", "none");
                });
        },
        paginatedDatalt() {
            // console.log('this.itemgamepp',this.itemgamepp);
            this.datalt = this.itemgamepp.slice(
                (this.pagelt - 1) * this.perPagelt,
                this.pagelt * this.perPagelt
            );
        },
        clickCallbacklt(pageNum) {
            this.pagelt = pageNum;
            this.paginatedDatalt();
        },
        closePopupItemGame() {
            $(".popupItemGame").hide();
            $(".wraper-root").removeClass("z_index_in");
            $("body").css("overflow", "initial");
            this.datalt = [];
        },
        gameHover(index) {
            this.hoveredIndex = index;
            $(".sc-btn").addClass("inactive");
        },
        gameHoverRemove() {
            this.hoveredIndex = null;
            $(".sc-btn").removeClass("inactive");
        },
        searchGame(e) {
            e.preventDefault();
            let keyname = $(".key_search").val();
            let act_length = this.listGaming.length;
            let dataArray = [];
            let set = 0;
            $.each(this.listGaming, function (i) {
                if (i + 1 == act_length) {
                    set = 1;
                }
                if (
                    this.title.indexOf(keyname) != -1 ||
                    this.code.indexOf(keyname) != -1
                ) {
                    return dataArray.push(this);
                }
                if (set == 1) {
                    // console.log('action: ', dataArray);
                    // return dataArray
                    // this.listGamingsearch = dataArray
                    // this.totalCountlt = this.itemgamepp.length
                    // this.paginatedDatalt()
                    // this.datalt = dataArray
                    // console.log('action: ', this.listGamingsearch);
                }
            });
            this.itemgamepp = dataArray;
            this.totalCountlt = this.itemgamepp.length;
            this.paginatedDatalt();
            // this.listGamingsearch = dataArray
            // console.log('dataArray',dataArray);
        },
        getMaintenance() {
            this.showModal_bt = true;
        }
    }
};
</script>
<style>
</style>
